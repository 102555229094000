<template>
  <div class="container">
    <div class="bottom">
      <img src="../../static/bottomlogo.png" />
    </div>
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="2"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content style="background: #F5FFEF;padding-top: 0.8rem">
    <div class="title">
      <p>四学期包含68讲内容，理论结合实践，动手又动脑。我们看一看每个学期都包含哪些知识点吧。</p>
    </div>
    <div class="classcontent">
      <div class="checkbutton">
        <div :class="index==1?'buttonitemcheck':'buttonitem'"   @click="changgepic(1)"> <span class="right"></span>  <h3>课程介绍</h3></div>
        <div :class="index==2?'buttonitemcheck':'buttonitem'"  @click="changgepic(2)">  <span class="right"></span> <h3>配套教材</h3></div>
        <div :class="index==3?'buttonitemcheck':'buttonitem'"    @click="changgepic(3)"> <span class="right"></span>  <h3>讲师介绍</h3></div>
        <div :class="index==4?'buttonitemcheck':'buttonitem'"  @click="changgepic(4)"> <span class="right"></span><h3>操作演示</h3></div>
        <div :class="index==5?'buttonitemcheck':'buttonitem'"  @click="changgepic(5)"> <span class="right"></span><h3>试听课程</h3></div>
      </div>
      <div v-if="index<4" class="content">
      <img :src="picsrc" ></img>
      </div>
      <div v-if="index==4" class="content2">
      
          <div class="centercontent">
            <div class="contentttile">
                <h3>操作简单</h3>
                <p>代课老师只需按照视频中的引导操作即可完成整个课堂的教学任务，弥补这类师资力量不足的问题。</p>
            </div>
            <div class="contenoper">
                <h3>操作视频</h3>
               <div class="videocontent">
                <div class="xmvideo">
                            <video-player  style="width: 100%;height: 100%;object-fit:cover;"  class="video-player vjs-custom-skin"
                                           ref="videoPlayer"
                                           :playsinline="true"
                                           :options="playerOptions"
                            ></video-player>
                        </div>
                        <div class="contentproduce">
                          <div class="produceitem">
                            <div class="itemimg"><img src="../../static/勾选.png"/></div>
                            <div class="itemcontent"><span>第一步 插入U盘 自动登录</span></div>
                          </div>
                          <div class="produceitem">
                            <div class="itemimg"><img src="../../static/勾选.png"/></div>
                            <div class="itemcontent"><span>第二步 点击player进入 课程列表</span></div>
                          </div>
                          <div class="produceitem">
                            <div class="itemimg"><img src="../../static/勾选.png"/></div>
                            <div class="itemcontent"><span>第三步 选择课程播放</span></div>
                          </div>
                          <div class="produceitem">
                            <div class="itemimg"><img src="../../static/勾选.png"/></div>
                            <div class="itemcontent"><span>第四步 知识点解析 动手动脑</span></div>
                          </div>
                          <div class="produceitem">
                            <div class="itemimg"><img src="../../static/勾选.png"/></div>
                            <div class="itemcontent"><span>第五步 我是小神 辨识中草药</span></div>
                          </div>
                        </div>
               </div>
            </div>
          </div>
      </div>
             <div v-if="index==5" class="content3">
          <div class="centercontent">
            <div class="contentttile">
                <h3>第四讲 中医就在身边（一）</h3>
                <p>生活习惯中的中医道理 橘子浑身都是宝；</p>
            </div>
            <div class="contenoper">
             
               <div class="videocontent">
                <div class="xmvideo">
                            <video-player  style="width: 100%;height: 100%;object-fit:cover;"  class="video-player vjs-custom-skin"
                                           ref="videoPlayer"
                                           :playsinline="true"
                                           :options="videoOptions"
                            ></video-player>
                        </div>
                        </div>
               </div>
            </div>
          </div>

    </div>
   
    <div class="buytap">
   <div class="school"></div>
    </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0;position: absolute;bottom: 0;width: 100%;">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>
</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "classcontent",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return{
      index:1,
      picsrc:require('../../static/classcontent.png'),
      playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: true, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "https://videotest.bjshiyi.cn/sv/639cd8f6-186c4525bfa/639cd8f6-186c4525bfa.mp4" //url地址
                    }],
                    poster: "https://videotest.bjshiyi.cn/e4aef7a0be2671edb35634b6fdcb0102/snapshots/6afb82b5e9634b4b98b399c46d5a7689-00002.jpg", //你的封面地址
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                },
                videoOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "https://videotest.bjshiyi.cn/sv/15df7cbe-18a8bfc51bb/15df7cbe-18a8bfc51bb.mp4" //url地址
                    }],
                    poster: "https://videotest.bjshiyi.cn/43bfcc2051ce71eeb987752281fd0102/snapshots/59628e7c4dcd4541bb4173f41eaeeea2-00002.jpg", //你的封面地址
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                }

    }
  },methods:{
    changgepic(ty){
      this.index=ty;
        if(this.index==1){
          this.picsrc=require('../../static/classcontent.png');
        }else if(this.index==2){
          this.picsrc=require('../../static/jiaocai.png');
        }else if(this.index==3){
          this.picsrc=require('../../static/jiangshi.png');
          
        }
    }

  }
}
</script>

<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);
  box-sizing: border-box;
}
.title{
  width: 43rem;
  height: 2.9rem;
  background-color: #21A958;
  margin: 0 auto;
  border-radius: 0.6rem;
  box-sizing: border-box;

  p{
    margin: 0;
    text-align: center;
    padding-top: 0.8rem;

    color: #ffffff;
    font-size: 0.8rem;
   vertical-align: center; 
    font-family: SourceHanSansSC;
  }
}
.classcontent{
  margin: 28px auto;
  width: 38rem;
  display: flex;
  flex-direction: row;
  .checkbutton{
   margin-top:-5px;
    display: flex;
    flex-direction: column;
    .buttonitem{
      position: relative;
      margin-top:35px;
      width: 145px;
      padding: 13px;
      height: 70px;
      font-size: 30px;
    
        h3{
          color: #ffffff; font-size: 28px;
        }

        background: linear-gradient(-41deg, #85C25F, #46B872);
  
    }
    .buttonitemcheck{
      position: relative;
      margin-top:35px;
      width: 145px;
      padding: 13px;
      height: 70px;
      font-size: 30px;
      .right{
        position: absolute;  
    width: 0px;  
    height: 0px;  
    line-height: 0px;/*为了防止ie下出现题型*/  
    border-bottom: 11px solid transparent;  
    border-left: 11px solid #89b007;  
    border-top: 11px solid transparent;  
    top: 25px;  
    right: -10px;  
      }
        h3{
          color: #ffffff; font-size: 28px;
        }

        background: linear-gradient(-41deg, #85C25F, #46B872);
  
    }
 
    
  }
  .buttonitemcheck:hover{
   cursor: pointer;
  }
  .buttonitem:hover{
   cursor: pointer;
  }
  .content{

   width:904px;
   height: 550px;

  
  img{
    height: 100%;
    width: 100%;
  }
}
.content2{
  margin: 0 auto;
  background: #FFFFFF;
  width: 904px;
  border-radius: 10px;
  margin-left: 13px;
box-shadow: 0 0 5px 2px #97ccac;
    height: 550px;
    .centercontent{
      display: flex;
      flex-direction: column;
      margin: 20px 40px;
      .contentttile{
        display: flex;
        flex-direction: column;
   
        h3{
          width: 153px;
       height: 38px;
       line-height: 1px;
       padding: 5px;
          background: #6DAB68;
          color: #ffffff;
          font-size: 28px;
          padding: 20px;
        }
        p{
          font-size: 21px;
          color: #333333 ;
          width: 100%;
          font-family: SourceHanSansSC;
        }
      }
      .contenoper{
        h3{
          width: 153px;
       height: 38px;
       line-height: 1px;
       padding: 5px;
          background: #6DAB68;
          color: #ffffff;
          font-size: 28px;
          padding: 20px;
        }
      }
    }
    
    .videocontent{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
     .xmvideo{
                
                width:480px;
               
            }
            .contentproduce{
                display: flex;
                flex-direction: column;
                margin-left: 15px;
              .produceitem{
                display: flex;
                flex-direction: row;
             
             margin-bottom:12px;
                .itemimg{
                  width: 22px;
                  height: 22px;
                  img{
                    width: 100%;
                    height: 100%;
                  }
                }
                .itemcontent{
                  span{
                    padding: 0;
                    white-space: pre-line;
                      font-size: 20px;
                      color:#333333;
                      font-family: SourceHanSansSC;
                    
                  }
                }

              }
            }
    }
}
.content3{
  margin: 0 auto;
  background: #FFFFFF;
  width: 904px;
  border-radius: 10px;
  margin-left: 13px;
box-shadow: 0 0 5px 2px #97ccac;
    height: 550px;
    .centercontent{
      display: flex;
      flex-direction: column;
      margin: 20px 40px;
      .contentttile{
        display: flex;
        flex-direction: column;
   
        h3{
          width: 390px;
       height: 38px;
       line-height: 1px;
       padding: 5px;
          background: #6DAB68;
          color: #ffffff;
          font-size: 28px;
          padding: 20px;
        }
        p{
          font-size: 21px;
          color: #333333 ;
          width: 100%;
          font-family: SourceHanSansSC;
        }
      }
      .contenoper{
        h3{
          width: 153px;
       height: 38px;
       line-height: 1px;
       padding: 5px;
          background: #6DAB68;
          color: #ffffff;
          font-size: 28px;
          padding: 20px;
        }
      }
    }
    
    .videocontent{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
     .xmvideo{
              
                width:720px;
               
            }
           
    }
}
}
.buytap{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 1.51rem;

        justify-items: center;
}

.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}
</style>